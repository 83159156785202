<template>
  <v-card :loading="loading" flat>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <th>{{ $t("created") }}</th>
            <td>
              {{ formatDateTime(details.created) }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("elapsed time") }}</th>
            <td>
              {{ formatDuration(details.elapsed_time, true) }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("client version") }}</th>
            <td>
              {{ details.version }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("customer number") }}</th>
            <td>
              {{ details.customer_number }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("customer type") }}</th>
            <td>
              {{ details.customer_type }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("person email") }}</th>
            <td>
              {{ details.person_email }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("IP") }}</th>
            <td>
              {{ details.ip }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("HTTP method") }}</th>
            <td>
              {{ details.method }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("HTTP status") }}</th>
            <td>
              {{ details.status }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("secure") }}</th>
            <td>
              <boolean-value v-model="details.secure" colored />
            </td>
          </tr>
          <tr>
            <th>{{ $t("is staff") }}</th>
            <td>
              <boolean-value v-model="details.staff" colored />
            </td>
          </tr>
          <tr>
            <th>{{ $t("staff username") }}</th>
            <td>
              {{ details.staff_username }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("URL") }}</th>
            <td>
              {{ details.url }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("view name") }}</th>
            <td>
              {{ details.view }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("view args") }}</th>
            <td>
              {{ details.args }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("view kwargs") }}</th>
            <td>
              {{ details.kwargs }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("language") }}</th>
            <td>
              {{ details.language }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("session") }}</th>
            <td>
              {{ details.session }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("header") }}</th>
            <td>
              <v-simple-table v-if="details.header != null" dense>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="(value, key, index) in details.header"
                      :key="index"
                    >
                      <th>{{ key }}</th>
                      <td>{{ value }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </tr>
          <tr>
            <th>{{ $t("body") }}</th>
            <td>
              {{ details.data }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("response") }}</th>
            <td>
              {{ details.response }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import formatDateTime from "@/utils/mixins/formatDateTime";
import BooleanValue from "@/components/basics/BooleanValue";
import formatDuration from "@/utils/mixins/formatDuration";

export default {
  name: "AccessLogDetails",
  components: {
    BooleanValue,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
  },
  mixins: [formatDateTime, formatDuration],
  data: () => ({
    loading: true,
    details: {},
  }),
  methods: {
    getLogDetails(id) {
      var that = this;
      that.loading = true;
      this.$http
        .get("logs/access-logs/" + this.id, { baseURL: this.baseUrl })
        .then((response) => {
          that.details = response.data;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  watch: {
    id(newValue) {
        this.getLogDetails();
    },
  },
  mounted() {
    this.getLogDetails();
  },
};
</script>

<i18n>
{
  "en": {
    "created": "Created",
    "elapsed time": "Elapsed time",
    "client version": "Client version",
    "customer number": "Customer number",
    "customer type": "Customer type",
    "person email": "Person email",
    "IP": "IP",
    "HTTP method": "HTTP method",
    "HTTP status": "HTTP status",
    "secure": "Secure",
    "is staff": "Is staff",
    "staff username": "Staff username",
    "URL": "URL",
    "view name": "View name",
    "view args": "View args",
    "view kwargs": "View kwargs",
    "language": "Language",
    "session": "Session",
    "header": "Header",
    "body": "Body",
    "response": "Response"
  },
  "de": {
    "created": "Angelegt",
    "elapsed time": "Benötigte Zeit",
    "client version": "Client Version",
    "customer number": "Kundennummer",
    "customer type": "Kundentyp",
    "person email": "Personen Email",
    "IP": "IP",
    "HTTP method": "HTTP Methode",
    "HTTP status": "HTTP Status",
    "secure": "Secure",
    "is staff": "Ist Mitarbeiter",
    "staff username": "Mitarbeiter Benutzername",
    "URL": "URL",
    "view name": "View Name",
    "view args": "View args",
    "view kwargs": "View kwargs",
    "language": "Sprache",
    "session": "Session",
    "header": "Header",
    "body": "Body",
    "response": "Response"
  }
}
</i18n>
